@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';
@import '../../../../../styles/constants/fonts.scss';

.container__invoices--subtitle {}

.container__invoices--header,
.container__invoices--header-second {
    height: 40px;
    border-bottom: solid 2px $cool_blue;
    display: flex;
    flex-direction: row;
    padding: 0 28px 0 24px;
    margin-bottom: 12px;

    @include responsive(m) {
        padding: 0 17px 0 24px;
    }

    @include responsive(sm) {
        padding: 0 23px 0 24px;
    }

    .sortable-column__items--text {
        @include responsive(sm) {
            font-size: 0.625rem;
            white-space: nowrap;
        }
    }

    .sortable-column__items--icon {
        @include responsive(sm) {
            margin-left: 3px;
        }
    }

    .selectable-column__items {
        .selectable-column__items--text {
            @include responsive(sm) {
                white-space: nowrap;
            }
        }
    }
}

.container__invoices--header {
    .sortable-column__items {
        display: flex;
        justify-content: flex-start;
        padding-right: 20px;

        // width: 200px;
        &--text {
            font-size: 11px;
            font-weight: bold;
            font-family: Frutiger;
            color: $cool_blue;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--icon {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
    }

    .sortable-column__items:first-child {
        width: 20%;
    }

    .sortable-column__items:nth-child(2) {
        width: 20%;

    }

    .sortable-column__items:nth-child(3) {
        width: 20%;
    }

    .sortable-column__items:nth-child(4) {
        width: 20%;
    }

    .sortable-column__items:nth-child(5) {
        width: 20%;
        padding-right: 5px;
    }

    .sortable-column__items:nth-child(8) {
        width: 14%;
    }

    .selectable-column__items {
        width: 10%;
        justify-content: flex-end;
        white-space: nowrap;
    }

    &--buyer {
        display: none;
    }

    &--serial {
        width: 18%;
        display: flex;
        .sortable-column__items:first-child {
            width: 100%;
        }
    }
}

// ============ TABELA 2 ============
.container__invoices--header-second {
    .sortable-column__items {
        display: flex;
        justify-content: flex-start;

        // width: 200px;
        &--text {
            font-size: 11px;
            font-weight: bold;
            font-family: Frutiger;
            color: $cool_blue;
            display: flex;
            align-items: center;
        }

        &--icon {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
    }

    .sortable-column__items:first-child {
        width: 20%;
    }

    .sortable-column__items:nth-child(2) {
        width: 20%;

    }

    .sortable-column__items:nth-child(3) {
        width: 20%;
    }

    .sortable-column__items:nth-child(4) {
        width: 20%;
    }

    .sortable-column__items:nth-child(5) {
        width: 20%;
        padding-right: 5px;
    }

    .sortable-column__items:nth-child(8) {
        width: 14%;
    }

    .selectable-column__items {
        width: 10%;
        justify-content: flex-end;
        white-space: nowrap;
    }

}