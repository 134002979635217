@import '../../../../../media-query';
@import '../../../../../styles/constants/colors.scss';

.anticipation-invoice-selection__invoice-list--error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45vh;
    min-height: 400px;

    .anticipation-invoice-selection__invoice-list__error-message {
        font-family: 'Catamaran', sans-serif;
        font-size: 1.25rem;
        color: $scorpion;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
    }

    .button__style--back .MuiButton-contained:hover,
    .button__style--back .MuiButton-contained {
        max-width: 220px;
    }
}

.anticipation-invoice-selection__invoice-list--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 279px;
    font-family: 'Catamaran', sans-serif;
    font-size: 1.25rem;
    color: $scorpion;
    text-align: center;
    line-height: 20px;
    white-space: pre-wrap;
    background-color: $white_two;

    .button__style--back {
        margin-top: 20px;
    }
}

.anticipation-invoice-selection__invoice-list--loading {
    .gfLNMF {
        height: 18px;
        width: 18px;

        .iepmoa {
            height: 9px;
            width: 9px;
            background-color: $white;
        }
    }
}

.invoice-list-item__items {
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $white_two;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 6px;

    @include responsive(m) {
        max-width: 100%;
    }

    @include responsive(sm) {
        max-width: 61.6%;
    }

    &--cell {
        font-family: Frutiger;
        font-size: 15px;
        color: greyish_brown;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;

        &--buyer {
            display: none;
        }
        &--serial {
            width: 13%;
        }
    }

    &--cell:first-child {
        width: 20%;
    }

    &--cell:nth-child(2) {
        width: 20%;
    }
    &--cell:nth-child(3) {
        width: 18%;
    }
    &--cell:nth-child(4) {
        width: 22%;
    }
    &--cell:nth-child(5) {
        width: 22%;
    }

    &--cell:nth-child(6) {
        width: 5%;
        justify-content: flex-end;
        display: flex;
    }

}

.invoice-list__overflow {
    width: 100% !important;
}

// ========= TABELA 2 ==========
.invoice-list-item__items-second {
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $white_two;
    display: flex;
    height: 52px;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 6px;

    @include responsive(sm) {
        max-width: 61.6%;
    }

    @include responsive(m) {
        max-width: 100%;
    }

    &--cell {
        font-family: Frutiger;
        font-size: 15px;
        color: greyish_brown;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--cell:first-child {
        width: 20%;
    }

    &--cell:nth-child(2) {
        width: 20%;
    }
    &--cell:nth-child(3) {
        width: 18%;
    }
    &--cell:nth-child(4) {
        width: 22%;
    }
    &--cell:nth-child(5) {
        width: 22%;
    }

    &--cell:nth-child(6) {
        width: 5%;
        justify-content: flex-end;
        display: flex;
    }
}