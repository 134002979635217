/*

 Custom styles for Finvex.Tech organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

.login--logo {
  width: 173px !important;
}
.login--form-logo-image {
  width: 210px !important;
}

.header--logo,
.container__header-provider .header-provider--logo {
    max-width: 165px !important;
}
